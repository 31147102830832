import React from 'react';

const ResidentialDetails = () => <>
  <p>
    The Residential Service Rate is the
    {" "}
    <a
      href="https://duquesnelight.com/service-reliability/service-map/rates/residential-rates#price-to-compare"
      target="_blank"
      rel="noopener noreferrer"
      style={{textDecoration: 'underline'}}
    >
      standard default service supply rate
    </a>
    {" "}
    for DLC customers. The estimates above include distribution, transmission, supply charges and surcharges.
  </p>
</>;

const ETouWholeHomeDetails = () => (
  <div>
    <p>
      We’re now offering time-based electricity supply rates during peak, off-peak, and super off peak time periods for our residential customers who are driving electric:
    </p>
    <ul>
      <li>
        During off-peak and super off-peak periods, electricity supply rates are lower than DLC’s standard default service supply rate and, during the peak time period, the rate is higher.
      </li>
      <li>
        The rates apply to all of the electricity usage at your home, not only to your EV charging.
      </li>
      <li>
        If you’re able to shift the majority of your electricity consumption to off-peak and super off-peak times, such as overnight, this rate might be right for you.
      </li>
    </ul>
    <p>
      Find more information about eligibility and current rates and answers to frequently asked questions on our
      {" "}
      <a
        href="https://duquesnelight.com/energy-money-savings/electric-vehicles/wholehome-ev-rate"
        target="_blank"
        rel="noopener noreferrer"
        style={{textDecoration: 'underline'}}
      >
        WholeHome EV Rate webpage
      </a>
      .
    </p>
    <p className="text-center">
      <a
        href="https://duquesnelight.com/energy-money-savings/electric-vehicles/wholehome-ev-rate"
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-ae"
      >
        Apply Now
      </a>
    </p>
  </div>
);

const ResidentialEGSDetails = () => <>
  <p>
    If you purchase electricity supply from an electric generation supplier (EGS) and not Duquesne Light, you have EGS supply. The estimates above include distribution, transmission, supply charges and surcharges.
  </p>
</>;

// Fixed monthly charges
const DISTRIBUTION_CUSTOMER_CHARGE_PER_MONTH = 13.00;
// Still the same value as of May 2022 update

const RETAIL_MARKET_ENHANCEMENT_SURCHARGE_PER_MONTH = 0.01;
const SMART_METER_CHARGE_PER_MONTH = 0;

// Distribution charges
const BASE_DISTRIBUTION_CHARGE_IN_DOLLARS_PER_KWH = 0.082479;
// Still the same value as of May 2022 update

const UNIVERSAL_SERVICE_CHARGE_IN_DOLLARS_PER_KWH = 0.01019;
const EEC_AND_DR_SURCHARGE_IN_DOLLARS_PER_KWH = 0;
const EEC_SURCHARGE_IN_DOLLARS_PER_KWH = 0.0015;

// A Fee which is a % of the fixed + distribution charges
const DSIC_PERCENT_AS_DECIMAL = -0.0004; // -0.04%

const TRANSMISSION_CHARGE_IN_DOLLARS_PER_KWH = 0.019523;

const GENERATION_POLR_CHARGE_IN_DOLLARS_PER_KWH = 0.084472;
// Changed May 2022 as per SUPPLEMENT NO. 44 TO ELECTRIC – PA. P.U.C. NO. 25, Fifth Revised Page No. 98


// Supply charges: Peak, Off-Peak, Super Off-Peak
const EV_TOU_SUPPLY_CHARGE_PEAK_IN_DOLLARS_PER_KWH = 0.112348;
// Changed Dec 2022 as per SUPPLEMENT NO. 52TO ELECTRIC –PA. P.U.C. NO. 25

const EV_TOU_SUPPLY_CHARGE_OFF_PEAK_IN_DOLLARS_PER_KWH = 0.072656;
// Changed Dec 2022 as per SUPPLEMENT NO. 52TO ELECTRIC –PA. P.U.C. NO. 25

const EV_TOU_SUPPLY_CHARGE_SUPER_OFF_PEAK_IN_DOLLARS_PER_KWH = 0.055762;
// Changed Dec 2022 as per SUPPLEMENT NO. 52TO ELECTRIC –PA. P.U.C. NO. 25

const monthlyCharges = [
  {
    name: 'Distribution Customer charge',
    charge: DISTRIBUTION_CUSTOMER_CHARGE_PER_MONTH
  },
  {
    name: 'Retail Market Enhancement Surcharge, Rider 1',
    charge: RETAIL_MARKET_ENHANCEMENT_SURCHARGE_PER_MONTH
  },
  {
    name: 'Smart Meter Charge, Rider 20',
    charge: SMART_METER_CHARGE_PER_MONTH
  }
];

const distributionCharges = [
  {
    name: 'Distribution charge',
    charge: BASE_DISTRIBUTION_CHARGE_IN_DOLLARS_PER_KWH
  },
  {
    name: 'Universal Service Charge, Rider 5',
    charge: UNIVERSAL_SERVICE_CHARGE_IN_DOLLARS_PER_KWH
  },
  {
    name: 'EEC&DR Surcharge, Rider 15',
    charge: EEC_AND_DR_SURCHARGE_IN_DOLLARS_PER_KWH
  },
  {
    name: 'EEC Surcharge, Rider 15A',
    charge: EEC_SURCHARGE_IN_DOLLARS_PER_KWH
  }
];

const commonRateElements = [
  ...monthlyCharges.map(({name, charge}) => ({
    name,
    rateElementType: 'FixedPerMonth',
    rateComponents: [
      {
        charge,
        name
      }
    ]
  })),
  ...distributionCharges.map(({name, charge}) => ({
    name,
    rateElementType: 'MonthlyEnergy',
    rateComponents: [
      {
        charge,
        name
      }
    ]
  })),
  ...monthlyCharges.map(({name, charge}) => ({
    name: `DSIC - ${name}`,
    rateElementType: 'FixedPerMonth',
    rateComponents: [
      {
        charge: charge * DSIC_PERCENT_AS_DECIMAL,
        name: `DSIC - ${name}`
      }
    ]
  })),
  ...distributionCharges.map(({name, charge}) => ({
    name: `DISC - ${name}`,
    rateElementType: 'MonthlyEnergy',
    rateComponents: [
      {
        charge: charge * DSIC_PERCENT_AS_DECIMAL,
        name: `DSIC - ${name}`
      }
    ]
  })),
  {
    name: 'Transmission charge',
    rateElementType: 'MonthlyEnergy',
    rateComponents: [
      {
        charge: TRANSMISSION_CHARGE_IN_DOLLARS_PER_KWH,
        name: 'Transmission Charge',
      },
    ]
  },
];

const evTouRate = {
  id: 'ev-tou',
  title: ['WholeHome EV Rate'],
  data: {
    name: 'WholeHome EV Rate',
    title: 'WholeHome EV Rate',
    rateElements: [
      ...commonRateElements,
      {
        name: 'Supply Charge',
        rateElementType: 'EnergyTimeOfUse',
        rateComponents: [
          {
            charge: EV_TOU_SUPPLY_CHARGE_PEAK_IN_DOLLARS_PER_KWH,
            name: 'Peak Charge',
            hourStarts: [13,14,15,16,17,18,19,20]
          },
          {
            charge: EV_TOU_SUPPLY_CHARGE_OFF_PEAK_IN_DOLLARS_PER_KWH,
            name: 'Off Peak Charge',
            hourStarts: [6,7,8,9,10,11,12,21,22]
          },
          {
            charge: EV_TOU_SUPPLY_CHARGE_SUPER_OFF_PEAK_IN_DOLLARS_PER_KWH,
            name: 'Super Off Peak Charge',
            hourStarts: [23,0,1,2,3,4,5]
          }
        ]
      }
    ]
  },
  detailsComponent: ETouWholeHomeDetails
};

const dlcRates = [
  {
    id: 'residential-service',
    title: ['Standard Residential Service'],
    data: {
      name: 'Standard Residential Service',
      title: 'Standard Residential Service',
      rateElements: [
        ...commonRateElements,
        {
          name: 'Supply Charge',
          rateElementType: 'MonthlyEnergy',
          rateComponents: [
            {
              charge: GENERATION_POLR_CHARGE_IN_DOLLARS_PER_KWH,
              name: 'Default Supply Charge'
            }
          ]
        }
      ]
    },
    detailsComponent: ResidentialDetails
  },
  evTouRate
];

const egsRates = (egsPriceInCentsPerKwh) => {
  return [
    {
      id: 'residential-service-egs',
      title: ['Residential Service', '(EGS Supply)'],
      data: {
        name: 'Residential Service - EGS Supply',
        title: 'Residential Service - EGS Supply',
        rateElements: [
          ...commonRateElements,
          {
            name: 'Supply Charge',
            rateElementType: 'MonthlyEnergy',
            rateComponents: [
              {
                charge: egsPriceInCentsPerKwh / 100,
                name: 'EGS Supply Charge'
              }
            ]
          }
        ]
      },
      detailsComponent: ResidentialEGSDetails
    },
    evTouRate
  ]
};

export {
  dlcRates,
  egsRates
};