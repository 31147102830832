import fetchWrapper from "../../../../utils/fetch/fetchWrapper";
export const loadMaintenanceData = async (cars, userPrefs, setMaintenanceData, setIsLoading) => {
  const params = {
    annualmiles: userPrefs.get("milesDrivenAnnually"),
    postcode: userPrefs.get("zipcode"),
  };

  let maintenanceData = [];
  for (let i = 0; i < cars.length; i++) {
    let vehicle_handle = cars[i].handle;
    let url = new URL(
      `${process.env.REACT_APP_API_HOST}/vehicles/${vehicle_handle}/maintenance`
    );
    let searchParams = new URLSearchParams(params);
    url.search = searchParams;

    try {
      const resultData = await fetchWrapper(url.toString(), {
        method: "GET",
      });

      maintenanceData.push({
        handle: vehicle_handle,
        maintenance: resultData.maintenance.costs,
      });
    } catch (error) {
      console.log("Failed to load maintenance data.");
      console.log(error);
      setIsLoading(false);
    }
  }

  setMaintenanceData(maintenanceData);
  setIsLoading(false);
}